<template>
        <div class="Feature">
            <div class="feature-topSection">
                <div class="part4-title" v-if="!isID">{{ $t("home.more") }} <span>{{ $t("home.features") }}</span></div>
                <div class="part4-title" v-else><span>{{ $t("home.features") }}</span> {{ $t("home.more") }}</div>
            </div>
            <div class="feature-bottomSection">
                <div class="circle1-pos"><img class="circle1" src="./../../assets/imgs/pc/homepage/backCircle1.png" alt=""></div>
                <div class="circle2-pos"><img class="circle2" src="./../../assets/imgs/pc/homepage/backCircle2.png" alt=""></div>
                <div class="feature-card-section">
                    <div class="feature-card" v-for="(ele,index) in featureInfo" :key="content">
                        <div class="card-top">
                            <img class="icons" :src="ele.minicons" />
                            <div class="title">&nbsp;{{ele.title}}</div>
                        </div>
                        <p>
                            {{ele.content}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>

export default ({
    data(){
        return{
            isID:false,
            featureInfo:[{
                title: this.$t("home.team_strength"),
                minicons:require('@/assets/imgs/pc/homepage/pic41.png'),
                content:'The Arcanite team consists of industry professionals with expertise in property, sales, and finance, providing you with comprehensive business support.',
                },{
                title: this.$t("home.first_to_market"),
                minicons:require('@/assets/imgs/pc/homepage/pic42.png'),
                content:'First fast paced, mobile-focused project marketing platform, combining elements of proptech and fintech to streamline off the plan sales.',
                },{
                title: this.$t("home.network_effects"),
                minicons:require('@/assets/imgs/pc/homepage/pic43.png'),
                content:'Recruit and expand your own network at lightning speed with every new user adding more synergy to your profile, facilitating additional sales and business growth opportunities.',
                },{
                title: this.$t("home.unmatched_value"),
                minicons:require('@/assets/imgs/pc/homepage/pic44.png'),
                content:'Packages to suit all budgets, with clients claiming our products are on average 70% more cost effective than competitors, with more features on offer.',
                }
            ]
        }
    },
    created(){
        if(localStorage.getItem('country')=="id"){
            this.isID=true;
        }else{
            this.isID=false;
        }
    },
    setup() {
        
    },
})
</script>
<style lang="scss" scoped>
.Feature{
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    // gap: 30px;
    padding: 26px;

    .feature-topSection{
        align-self: flex-start;
        .part4-title{
            width: 163px;
            height: 84px;
            font-family: 'Poppins-Bold';
            font-size: 36px;
            line-height: 42px;
            color: #062440;
            text-align: left;
            margin-bottom: 30px;
            span{
                font-family: 'Poppins-Bold';
                font-size: 36px;
                line-height: 42px;
                color: #1890FF;
            }
        }
    }

    .feature-bottomSection{
        position: relative;
        .circle1-pos{
            z-index: -2;
            position: absolute;
            top: -13px;
            right: -74px;
            .circle1{
                width: 258px;
                height: 257px;
            }
        }
        .circle2-pos{
            z-index: -2;
            position: absolute;
            bottom: 25px;
            left: -119px;
            .circle2{
                width: 258px;
                height: 257px;
            }
        }
        .feature-card-section{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: flex-start;
            gap: 20px;

            .feature-card{
                display: flex;
                flex-direction: column;
                box-sizing: border-box;
                align-items: flex-start;
                padding: 20px;
                gap: 20px;
                width: 100%;
                height: 100%;
                background: rgba(255, 255, 255, 0.6);
                border: 1px solid #FFFFFF;
                backdrop-filter: blur(40px);
                border-radius: 10px;
                flex: none;
                order: 0;
                flex-grow: 0;
                .card-top{
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    .icons{
                        width: 34px;
                        height: 34px;
                    }
                    .title{
                        font-family: 'Poppins-bold';
                        font-style: normal;
                        font-size: 18px;
                        line-height: 24px;
                        letter-spacing: 0.06px;
                        color: #062440;
                        flex: none;
                        order: 1;
                        flex-grow: 0;
                    }
                }
            }
            
            p{
                font-family: 'Poppins';
                font-style: normal;
                font-size: 14px;
                line-height: 20px;
                display: flex;
                align-items: center;
                color: #062440;
                flex: none;
                order: 1;
                align-self: stretch;
                flex-grow: 0;
                text-align: left;
            }
        }
    }
}



    
</style>
